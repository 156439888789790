/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Switch,
  TextareaAutosize,
  TextField,
  Typography,
} from '@mui/material'
import { icons } from 'src/assets'
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  memo,
} from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { Loader, SelectInput, TextFieldLabel } from 'src/components/UI'
import DropDown from 'src/components/UI/CustomUI/atoms/DropDown'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import ImagesSelector from 'src/components/UI/CustomUI/molecules/ImagesSelector'
import {
  getOrderById,
  getTerritoryRepairItemsForSelect,
  getJob,
  getItems,
} from 'src/ducks/selectors'
import { WorkOrdersContext } from '../../../../../context'
import { debounce } from 'lodash'

import styles from './styles.module.scss'
import {
  CURRENCY_MASK,
  isEmpty,
  round,
  unMaskedInput,
  ITEM_CATEGORY,
  STATUS,
  CURRENCY_MASK_NEGATIVE,
  ORDER_STATUS,
  SEVERITY_OPTIONS,
  ITEM_CATEGORY_OPTIONS,
  roundWithoutCents,
} from 'src/helpers'
import { createNumberMask } from 'text-mask-addons'
import { NewItem, workOdersTypes } from '../../../../../context/types'
import SwiperCore, { Mousewheel } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import { ImageProps } from './types'
import CurrencyTextField from 'src/components/UI/CustomUI/molecules/CurrencyTextField'
import { filesActions, itemsActions } from 'src/ducks/actions'
import { Item } from 'src/ducks/types'
import GeneralImagesSelector from 'src/components/UI/CustomUI/molecules/GeneralImageSelector'
import Comments from '../Comments'
import NewItemComments from './NewItemComments'
import Modal from 'src/components/UI/CustomUI/molecules/Modal'
import Images from '../../../Images'
import ImagePicker from 'src/components/UI/CustomUI/atoms/ImagePicker'
import env from '@beam-australia/react-env'

SwiperCore.use([Mousewheel])

const FILES_URL = env('FILES_URL') ?? ''

export const DISCLAIMER_OPTIONS = [
  {
    key: 'Price to be adjusted per quantity required.',
    value: 'Price to be adjusted per quantity required',
  },
  {
    key: 'Price subject to change per onsite conditions.',
    value: 'Price subject to change per onsite conditions',
  },
  {
    key: 'Price is for area pictured and is subject to change per additional areas required.',
    value:
      'Price is for area pictured and is subject to change per additional areas required',
  },
  {
    key: 'Price subject to change if replacement is required.',
    value: 'Price subject to change if replacement is required',
  },
  {
    key: 'Price to be adjusted per linear footage required.',
    value: 'Price to be adjusted per linear footage required',
  },
  {
    key: 'Price subject to change if additional repairs are required.',
    value: 'Price subject to change if additional repairs are required',
  },
  {
    key: 'No photo(s) provided: price subject to change per onsite conditions.',
    value:
      'No photo(s) provided: price subject to change per onsite conditions',
  },
  {
    key: 'Price subject to change per style desired/availability of item.',
    value: 'Price subject to change per style desired/availability of item',
  },
]

export const evaluateReasons = [
  {
    key: 'Need More Information',
    value: 'Need More Information',
  },
  {
    key: 'Require Diagnostic',
    value: 'Require Diagnostic',
  },
  {
    key: 'Out of Scope/Expert',
    value: 'Out of Scope/Expert',
  },
  {
    key: 'May Require Additional Inspection',
    value: 'May Require Additional Inspection',
  },
]

const propsAreEqual = (prevProps: ImageProps, nextProps: ImageProps) => {
  return (
    JSON.stringify(prevProps?.images) === JSON.stringify(nextProps?.images) &&
    JSON.stringify(prevProps.imageFiles) ===
    JSON.stringify(nextProps.imageFiles) &&
    prevProps.isSlider === nextProps.isSlider &&
    prevProps.hasImageLoader === nextProps.hasImageLoader &&
    prevProps.submitAction === nextProps.submitAction
  )
}

const MemoImagesSelector = memo((props: ImageProps) => {
  const {
    submitAction,
    images,
    imageFiles,
    isSlider,
    hasImageLoader,
    handleAllImage,
    isNewSelector,
  } = props

  return (
    <>
      {!isNewSelector ? (
        <ImagesSelector
          multiple
          submitAction={submitAction}
          images={images}
          imageFiles={imageFiles}
          height="auto"
          columns={4}
          isSlider={isSlider}
          hasImageLoader={hasImageLoader}
          hasAllImagesButton
          width={450}
          imageNumber={2}
          handleAllImage={() => {
            if (handleAllImage) handleAllImage()
          }}
        />
      ) : (
        <GeneralImagesSelector
          multiple
          submitAction={submitAction}
          images={images}
          imageFiles={imageFiles}
          height="auto"
          columns={4}
          // isSlider={isSlider}
          hasImageLoader={hasImageLoader}
          hasAllImagesButton
          width={450}
          imageNumber={2}
          handleAllImage={() => {
            if (handleAllImage) handleAllImage()
          }}
        />
      )}
    </>
  )
}, propsAreEqual)

/**
 *
 * @param columnView param for ocr review and edit mode
 * @returns
 */
const EstimateInfo = ({
  columnView,
  viewType,
  onItemChange,
}: {
  columnView?: boolean
  viewType?: string
  onItemChange?: (item: Item) => void
}) => {
  const dispatch = useDispatch()

  const territoryRepairItems = useSelector(getTerritoryRepairItemsForSelect)
  const job = useSelector(getJob())
  const {
    state: {
      item,
      editItem,
      selectedOrderId,
      selectedItemId,
      selectedTab,
      showPriceMatch,
      totalPatchLoading,
    },
    dispatch: dispatchContext,
  } = useContext(WorkOrdersContext)
  const [newItem, setNewItem] = useState(item)
  const [evaluateReason, setEvaluateReason] = useState<string[]>([''])
  const [showOverride, setShowOverride] = useState(false)
  const [initialTotalHitted, setInitialTotalHitted] = useState(false)
  const [priceMatchFileLoading, setPriceMatchFileLoading] =
    useState<boolean>(false)
  const currencyMaskNegative = createNumberMask(CURRENCY_MASK_NEGATIVE)
  const currencyMask = createNumberMask(CURRENCY_MASK)
  const order = useSelector(getOrderById(selectedOrderId as string))
  const [itemTotalLoading, setItemTotalLoading] = useState(false)
  const items = useSelector(getItems())
  const [openImagesModal, setOpenImagesModal] = useState(false)

  const isEditMode = viewType === 'EDIT'

  useEffect(() => {
    if (!isEmpty(item.evaluateReason)) {
      setEvaluateReason([item?.evaluateReason || ''])
    }
  }, [item])

  const isEditModal = useMemo(() => !isEmpty(item.itemId), [item])

  // Temp item used for price fields
  const [tempItem, setTempItem] = useState(item)

  const isJobClosed = job?.status === STATUS.CLOSED

  const disclaimerArray =
    (item.disclaimer && typeof item.disclaimer === 'string'
      ? item.disclaimer
        .split('.')
        .filter((element) => element)
        .map((item) => item.trim())
      : item.disclaimer) ?? []

  const changeItemValue = useCallback(
    (newValue, attr) => {
      if (!isEditModal)
        dispatchContext({
          type: workOdersTypes.SET_ITEM_VALUE,
          payload: { attr: attr, value: newValue },
        })
      else {
        dispatchContext({
          type: workOdersTypes.SET_EDIT_ITEM_VALUE,
          payload: { attr: attr, value: newValue },
        })
      }
    },
    [isEditModal]
  )

  const dispatchDisclaimer = (newSelected: any) => {
    dispatchContext({
      type: 'SET_ITEM_VALUE',
      payload: { attr: 'disclaimer', value: newSelected },
    })
    dispatchContext({
      type: 'SET_EDIT_ITEM_VALUE',
      payload: { attr: 'disclaimer', value: newSelected },
    })
  }
  const dispatchEvaluateReason = (newSelected: any) => {
    setEvaluateReason(newSelected)
    dispatchContext({
      type: 'SET_ITEM_VALUE',
      payload: { attr: 'evaluateReason', value: newSelected },
    })
    dispatchContext({
      type: 'SET_EDIT_ITEM_VALUE',
      payload: { attr: 'evaluateReason', value: newSelected },
    })
  }

  const _handleBlur = (event: any): void => {
    const disclaimer = event?.target?.value
    if (disclaimer?.length > 0) {
      const newValue: any = [...(disclaimerArray as string[]), disclaimer]
      dispatchDisclaimer(newValue)
    }
  }

  const _handleBlurEvaluateReason = (event: any): void => {
    const evReason = event?.target?.value
    if (evReason?.length > 0) {
      const newValue: any = [...(evaluateReason as string[]), evReason]
      dispatchEvaluateReason(newValue)
    }
  }

  const submitAction = useCallback(
    (e: any, flag?: boolean) => {
      const payload = !flag
        ? { attr: 'images', value: e }
        : { attr: 'imageFiles', value: e }

      if (isEditModal) {
        dispatchContext({ type: 'SET_EDIT_ITEM_VALUE', payload })
      } else {
        dispatchContext({ type: 'SET_ITEM_VALUE', payload })
      }
    },
    [isEditModal]
  )

  const onItemValueChanged = useMemo(
    () => debounce(changeItemValue, 0),
    [changeItemValue]
  )

  const setValues = (newSelected: any) => {
    const selectedItem = items.find((it) => it.id === selectedItemId)
    if (!isEditModal) {
      // dispatchContext({ type: 'RESET_ITEM_VALUE', payload: true })
      /* dispatchContext({
        type: 'SET_ITEM_VALUE',
        payload: { attr: 'totalPrice', value: 0 },
      })
      dispatchContext({
        type: 'SET_ITEM_VALUE',
        payload: { attr: 'totalLaborCost', value: 0 },
      })
      dispatchContext({
        type: 'SET_ITEM_VALUE',
        payload: { attr: 'totalMaterialCost', value: 0 },
      })
      dispatchContext({
        type: 'SET_ITEM_VALUE',
        payload: { attr: 'totalLaborHours', value: 0 },
      })
      dispatchContext({
        type: 'SET_ITEM_VALUE',
        payload: { attr: 'laborRate', value: 0 },
      }) */
      dispatchContext({
        type: 'SET_ITEM_VALUE',
        payload: { attr: 'category', value: ITEM_CATEGORY.UNCATEGORIZED },
      })
      dispatchContext({
        type: 'SET_ITEM_VALUE',
        payload: { attr: 'severity', value: 'Medium' },
      })
      dispatchContext({
        type: 'SET_ITEM_VALUE',
        payload: { attr: 'title', value: newSelected },
      })
      dispatchContext({
        type: 'SET_ITEM_VALUE',
        payload: { attr: 'displayTitle', value: newSelected },
      })
      selectedItem && fetchItemsTotal(newItem, isEditModal)
    } else {
      setNewItem({
        ...newItem,
        // category: ITEM_CATEGORY.UNCATEGORIZED,
        severity: 'Medium',
        title: newSelected,
      })
      // dispatchContext({
      //   type: workOdersTypes.SET_EDIT_ITEM_VALUE,
      //   payload: { attr: 'category', value: ITEM_CATEGORY.UNCATEGORIZED },
      // })
      dispatchContext({
        type: workOdersTypes.SET_EDIT_ITEM_VALUE,
        payload: { attr: 'severity', value: 'Medium' },
      })
      dispatchContext({
        type: workOdersTypes.SET_EDIT_ITEM_VALUE,
        payload: { attr: 'title', value: newSelected },
      })
    }
  }

  useEffect(() => {
    const isNotSelected =
      territoryRepairItems.findIndex((op) => op.label === newItem.title) === -1
    !isNotSelected &&
      !isEditModal &&
      newItem.id &&
      fetchItemsTotal({ ...newItem }, isEditModal)
  }, [])

  const handleInputChange = (newSelected: any) => {
    // dispatchContext({
    //   type: workOdersTypes.SET_TOTALS_LOADING,
    //   payload: true
    // });
    const isNotSelected =
      territoryRepairItems.findIndex((op) => op.label === newSelected) === -1
    const selectedRepairItem = territoryRepairItems.find(
      (op) => op.label === newSelected
    )
    const isUncategorized =
      newSelected.indexOf(ITEM_CATEGORY.UNCATEGORIZED) === -1

    if (selectedRepairItem && !initialTotalHitted && !isEditModal) {
      fetchItemsTotal(
        { ...(selectedRepairItem.key as any), quantity: item.quantity || 1 },
        isEditModal
      )
      setInitialTotalHitted(true)
    }
    if (isEditModal && initialTotalHitted) {
      dispatchContext({
        type: workOdersTypes.SET_TOTALS_LOADING,
        payload: false,
      })
    }

    if (isEditModal && initialTotalHitted && !isNotSelected) {
      fetchItemsTotal({ ...item, ...editItem }, isEditModal)
      setInitialTotalHitted(true)
    }
    // only cases were the item its new
    if (
      isNotSelected &&
      isUncategorized &&
      newSelected.length > 0 &&
      !isJobClosed
    ) {
      setValues(newSelected)
    }
  }

  const handleSelectNewItem = (newSelected: any) => {
    const { key: newItem } = newSelected
    if (newItem !== undefined) {
      if (!isEditModal) {
        !isEmpty(newSelected.label) &&
          fetchItemsTotal({ ...newSelected.key, quantity: 1 }, isEditModal)

        // dispatchContext({ type: 'RESET_ITEM_VALUE', payload: true })
        dispatchContext({
          type: 'SET_ITEM_VALUE',
          payload: { attr: 'unitPrice', value: newItem.unitPrice },
        })
        setTempItem({
          ...newItem,
          quantity: 1,
        })
        dispatchContext({
          type: 'SET_ITEM_VALUE',
          payload: { attr: 'hideFromClient', value: newItem?.hideFromClient },
        })
        dispatchContext({
          type: 'SET_ITEM_VALUE',
          payload: { attr: 'id', value: newItem?.id },
        })
      } else {
        fetchItemsTotal(
          {
            ...newSelected.key,
            quantity: 1,
            inspectionReportNote:
              editItem.inspectionReportNote || item.inspectionReportNote,
          },
          isEditModal
        )

        dispatchContext({
          type: workOdersTypes.SET_EDIT_ITEM_VALUE,
          payload: { attr: 'disclaimer', value: newItem.disclaimer },
        })
        dispatchContext({
          type: workOdersTypes.SET_EDIT_ITEM_VALUE,
          payload: {
            attr: 'totalLaborCost',
            value: newItem.unitLaborHours * newItem.laborRate,
          },
        })
        dispatchContext({
          type: workOdersTypes.SET_EDIT_ITEM_VALUE,
          payload: {
            attr: 'totalMaterialCost',
            value: newItem.unitMaterialCost,
          },
        })
        dispatchContext({
          type: workOdersTypes.SET_EDIT_ITEM_VALUE,
          payload: {
            attr: 'unitMaterialCost',
            value: newItem.unitMaterialCost,
          },
        })
        dispatchContext({
          type: workOdersTypes.SET_EDIT_ITEM_VALUE,
          payload: {
            attr: 'totalLaborHours',
            value: newItem.unitLaborHours,
          },
        })
        dispatchContext({
          type: workOdersTypes.SET_EDIT_ITEM_VALUE,
          payload: {
            attr: 'unitLaborHours',
            value: newItem.unitLaborHours,
          },
        })
        dispatchContext({
          type: workOdersTypes.SET_EDIT_ITEM_VALUE,
          payload: { attr: 'laborRate', value: newItem.laborRate },
        })
        dispatchContext({
          type: workOdersTypes.SET_EDIT_ITEM_VALUE,
          payload: { attr: 'quantity', value: 1 },
        })
        dispatchContext({
          type: workOdersTypes.SET_EDIT_ITEM_VALUE,
          payload: { attr: 'category', value: newItem.category },
        })
        dispatchContext({
          type: workOdersTypes.SET_EDIT_ITEM_VALUE,
          payload: { attr: 'severity', value: newItem.severity },
        })
        dispatchContext({
          type: workOdersTypes.SET_EDIT_ITEM_VALUE,
          payload: { attr: 'title', value: newItem.title },
        })
        dispatchContext({
          type: workOdersTypes.SET_EDIT_ITEM_VALUE,
          payload: { attr: 'margin', value: newItem.margin },
        })
        dispatchContext({
          type: workOdersTypes.SET_EDIT_ITEM_VALUE,
          payload: { attr: 'displayTitle', value: newSelected.label },
        })
        dispatchContext({
          type: workOdersTypes.SET_EDIT_ITEM_VALUE,
          payload: { attr: 'profit', value: newItem.profit },
        })
        dispatchContext({
          type: workOdersTypes.SET_EDIT_ITEM_VALUE,
          payload: { attr: 'totalCost', value: newItem.unitCost },
        })
        dispatchContext({
          type: workOdersTypes.SET_EDIT_ITEM_VALUE,
          payload: { attr: 'totalCost', value: newItem.unitCost },
        })

        dispatchContext({
          type: workOdersTypes.SET_EDIT_ITEM_VALUE,
          payload: { attr: 'id', value: selectedItemId },
        })

        // onItemChange && onItemChange(newItem)
      }

      dispatchContext({
        type: 'SET_ITEM_VALUE',
        payload: {
          attr: 'totalLaborCost',
          value: newItem.unitLaborHours * newItem.laborRate,
        },
      })
      dispatchContext({
        type: 'SET_ITEM_VALUE',
        payload: { attr: 'totalMaterialCost', value: newItem.unitMaterialCost },
      })
      dispatchContext({
        type: 'SET_ITEM_VALUE',
        payload: { attr: 'unitMaterialCost', value: newItem.unitMaterialCost },
      })
      dispatchContext({
        type: 'SET_ITEM_VALUE',
        payload: { attr: 'unitLaborHours', value: newItem.unitLaborHours },
      })
      dispatchContext({
        type: 'SET_ITEM_VALUE',
        payload: { attr: 'laborRate', value: newItem.laborRate },
      })
      dispatchContext({
        type: 'SET_ITEM_VALUE',
        payload: { attr: 'quantity', value: 1 },
      })
      dispatchContext({
        type: 'SET_ITEM_VALUE',
        payload: { attr: 'category', value: newItem.category },
      })
      dispatchContext({
        type: 'SET_ITEM_VALUE',
        payload: { attr: 'severity', value: newItem.severity },
      })
      dispatchContext({
        type: 'SET_ITEM_VALUE',
        payload: { attr: 'title', value: newItem.title },
      })
      dispatchContext({
        type: 'SET_ITEM_VALUE',
        payload: { attr: 'margin', value: newItem.margin },
      })
      dispatchContext({
        type: 'SET_ITEM_VALUE',
        payload: { attr: 'disclaimer', value: newItem.disclaimer?.trim() },
      })
      dispatchContext({
        type: 'SET_ITEM_VALUE',
        payload: { attr: 'displayTitle', value: newSelected.label },
      })
    }
  }

  useEffect(() => {
    setNewItem({ ...item, ...editItem })
    if (isEditModal) {
      setTempItem(item)
      if (columnView) {
        // fetchItemsTotal(item, isEditModal)
      }
    }
  }, [item])

  useEffect(() => {
    const selectedItem = items.find((it) => it.id === selectedItemId)

    isEditModal &&
      columnView &&
      dispatchContext({
        type: workOdersTypes.SET_ITEM,
        payload: { ...selectedItem, displayTitle: selectedItem?.title },
      })
  }, [items])


  const fetchItemsTotal = (
    requestItem: Partial<NewItem>,
    isEditModal: boolean
  ) => {
    if (!requestItem.id) {
      dispatchContext({
        type: workOdersTypes.SET_TOTALS_LOADING,
        payload: false,
      })
      return
    }

    // setItemTotalLoading(true)
    dispatchContext({
      type: workOdersTypes.SET_TOTALS_LOADING,
      payload: true,
    })

    let { ...request } = requestItem

    if ('unitMaterialCost' in request) {
      request.unitMaterialCost = Number(request.unitMaterialCost || 0)
    }
    if ('unitLaborHours' in request)
      request.unitLaborHours = Number(request.unitLaborHours)
    if ('margin' in request) request.margin = Number(request.margin)
    if ('totalLaborCost' in request)
      request.totalLaborCost = Number(request.totalLaborCost)
    if ('totalPrice' in request) request.totalPrice = Number(request.totalPrice)
    if ('minPriceRange' in request && request.minPriceRange)
      request.minPriceRange = Number(request.minPriceRange)
    if ('evaluateReason' in request && request.evaluateReason)
      request.evaluateReason = String(request.evaluateReason)
    if ('maxPriceRange' in request && request.maxPriceRange)
      request.maxPriceRange = Number(request.maxPriceRange)
    if ('quantity' in request && !request.quantity) request.quantity = 1

    if (item.itemId) request.itemId = item.itemId
    if (item.itemId) request.id = item.itemId
    dispatchContext({
      type: workOdersTypes.SET_ITEM_TO_FETCH_TOTALS,
      payload: request,
    })

    dispatch(
      itemsActions.updateItemTotals(
        request,
        (succ: boolean, it: Item | null) => {
          const resultItem = it || newItem
          setNewItem(resultItem)
          setTempItem(resultItem)
          setItemTotalLoading(false)

          if (!isEditModal) {
            dispatchContext({
              type: 'SET_ITEM_VALUE',
              payload: { attr: 'totalPrice', value: resultItem.totalPrice },
            })
            dispatchContext({
              type: 'SET_ITEM_VALUE',
              payload: {
                attr: 'totalLaborCost',
                value: resultItem.totalLaborCost,
              },
            })
            dispatchContext({
              type: 'SET_ITEM_VALUE',
              payload: {
                attr: 'totalMaterialCost',
                value: resultItem.totalMaterialCost,
              },
            })
            dispatchContext({
              type: 'SET_ITEM_VALUE',
              payload: { attr: 'totalCost', value: resultItem.totalCost },
            })
            dispatchContext({
              type: 'SET_ITEM_VALUE',
              payload: { attr: 'profit', value: resultItem.profit },
            })
          } else {
            dispatchContext({
              type: workOdersTypes.SET_EDIT_ITEM_VALUE,
              payload: { attr: 'totalPrice', value: resultItem.totalPrice },
            })
            dispatchContext({
              type: workOdersTypes.SET_EDIT_ITEM_VALUE,
              payload: {
                attr: 'totalLaborCost',
                value: resultItem.totalLaborCost,
              },
            })
            dispatchContext({
              type: workOdersTypes.SET_EDIT_ITEM_VALUE,
              payload: {
                attr: 'totalMaterialCost',
                value: resultItem.totalMaterialCost,
              },
            })
            dispatchContext({
              type: workOdersTypes.SET_EDIT_ITEM_VALUE,
              payload: { attr: 'totalCost', value: resultItem.totalCost },
            })
            dispatchContext({
              type: workOdersTypes.SET_EDIT_ITEM_VALUE,
              payload: { attr: 'profit', value: resultItem.profit },
            })
          }
          dispatchContext({
            type: workOdersTypes.SET_TOTALS_LOADING,
            payload: false,
          })
        }
      )
    )
    // setItemTotalLoading(false)
  }

  // const fetchItemsTotal = fetchItemsTotal()

  /*   const debounceFetchItemsTotal = useCallback(
      // debounce(fetchItemsTotal, columnView ? 1000 : 500),
      debounce(fetchItemsTotal, 0),
      []
    ) */

  const handleItemTotal = (propName: string) => {
    // a flag for checking if the Material cost, hours/qty, quantity, margin has been changed, or if they select a different repair item entirely.
    let valueChanged =
      (tempItem as any)[propName]?.toString() !==
      (newItem as any)[propName]?.toString()

    if (valueChanged && newItem.id) {
      fetchItemsTotal(newItem, isEditModal)
    }
  }

  const handleDownloadFile = (fileUrl: string) => {
    const url = fileUrl
    if (url) {
      window.open(url, '_blank')
    }
  }

  const handleChangeQuantity = (num: string) => {
    // if (!e.target.value) return
    const quantity = Math.max(1, Math.min(Number(num), Number(num)))
    setNewItem({
      ...newItem,
      quantity: quantity,
    })
    onItemValueChanged(quantity, 'quantity')
    dispatchContext({
      type: workOdersTypes.SET_TOTALS_LOADING,
      payload: true,
    })
    newItem.title &&
      fetchItemsTotal(
        {
          ...newItem,
          quantity: quantity,
        },
        isEditModal
      )
  }

  const handleDeletePriceMatchFile = () => {
    setNewItem({
      ...newItem,
      priceMatchFile: undefined,
    })
    onItemValueChanged(
      undefined,
      'priceMatchFile'
    )
  }

  const handleUploadPriceMatchFile = (files: File[]) => {
    setPriceMatchFileLoading(true)
    dispatchContext({
      type: workOdersTypes.SET_TOTALS_LOADING,
      payload: true,
    })
    dispatch(
      filesActions.uploadFile(
        files[0],
        (success: boolean, fileName: any, uploadUrl: any) => {
          if (success) {
            const file = {
              name: fileName,
              uploadUrl,
            }
            setNewItem({
              ...newItem,
              priceMatch: { ...newItem.priceMatch, file: file as any },
              priceMatchFile: file as any,
            })
            onItemValueChanged({ ...newItem.priceMatch, file: file as any }, 'priceMatch')
            onItemValueChanged(file, 'priceMatchFile')
          }
          setPriceMatchFileLoading(false)
          dispatchContext({
            type: workOdersTypes.SET_TOTALS_LOADING,
            payload: false,
          })
        }
      )
    )
  }

  const filesUrlIncludesLastSlash = FILES_URL[FILES_URL.length - 1] === "/"

  const handlePaste = (event: ClipboardEvent) => {
    const items = event.clipboardData?.items || []

    const newImages = [...(item?.images || []), ...(editItem?.images || [])]

    for (let i = 0; i < items.length; i++) {
      const item = items[i]
      if (item.type.indexOf('image') !== -1) {
        const blob = item.getAsFile()
        if (blob) {
          newImages.push(blob)
        }
      }
    }

    if (isEditModal) {
      dispatchContext({
        type: 'SET_EDIT_ITEM_VALUE',
        payload: { attr: 'images', value: newImages },
      })
    } else {
      dispatchContext({
        type: 'SET_ITEM_VALUE',
        payload: { attr: 'images', value: newImages },
      })
    }
  }

  useEffect(() => {
    document.addEventListener('paste', handlePaste)

    return () => {
      document.removeEventListener('paste', handlePaste)
    }
  }, [item, editItem])

  return (
    <Grid
      container
      spacing={columnView ? 1 : 3}
      flexDirection={columnView ? 'column-reverse' : 'row'}
    >
      <Grid
        container
        item
        spacing={2}
        xs={columnView ? 12 : showOverride ? 8 : 6}
      >
        {viewType !== 'OCR' && (
          <>
            <Grid container item xs={12} alignItems="flex-end" spacing={2}>
              <Grid item flex={1}>
                <SelectInput
                  label="Display title: "
                  value={item.displayTitle || ''}
                  onInputChange={handleInputChange}
                  onChange={handleSelectNewItem}
                  // onBlur={() => handleItemTotal('displayTitle')}
                  options={territoryRepairItems.map((option) => option)}
                  onFocus={(event) => {
                    event.target.select()
                  }}
                  renderOption={(props: any, item: any) => {
                    return (
                      <li
                        {...props}
                        key={item.id}
                      >{`${item.key.publicTitle} - ${item.key.category} - ${item.key.unitLaborHours} hours`}</li>
                    )
                  }}
                  size="small"
                  labelVariant="h6"
                  // disabled={vendorIsPaid}
                  freeSolo
                />
              </Grid>
              {/* <Grid item>
                <Button
                  variant='outlined'
                  sx={{ padding: 0, width: 41, height: 41, marginRight: 1 }}
                >
                  <Icon name='NorthEast' width='1rem' height='1rem' />
                </Button>
                <Button
                  variant='outlined'
                  sx={{ padding: 0, width: 41, height: 41 }}
                >
                  <Icon name='Link' width='1rem' height='1rem' />
                </Button>
              </Grid> */}
            </Grid>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={8}>
                <SelectInput
                  label="Category: "
                  // disabled
                  value={newItem.category || ''}
                  options={ITEM_CATEGORY_OPTIONS.map((option) => option)}
                  onChange={(newValue) => {
                    setNewItem({
                      ...newItem,
                      category: newValue.label as ITEM_CATEGORY,
                    })
                    onItemValueChanged(newValue.label, 'category')
                  }}
                  size="small"
                  labelVariant="h6"
                />
              </Grid>
              <Grid item xs={4}>
                <SelectInput
                  label="Severity: "
                  // disabled
                  value={newItem.severity || ''}
                  onChange={(newValue) => {
                    setNewItem({ ...newItem, severity: newValue.label })
                    onItemValueChanged(newValue.label, 'severity')
                  }}
                  options={SEVERITY_OPTIONS}
                  size="small"
                  labelVariant="h6"
                />
              </Grid>
            </Grid>

            {newItem.category === 'EVALUATE' && (
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <SelectInput
                    label="Evaluate Reason:"
                    placeholder="Select the reason"
                    value={evaluateReason || ''}
                    options={evaluateReasons.map((option) => option.value)}
                    size="small"
                    onChange={dispatchEvaluateReason}
                    // freeSolo
                    labelVariant="h6"
                  // maxCharacters={40}
                  // disabled={vendorIsPaid}
                  />
                </Grid>
                <div className={styles.ranges}>
                  <CurrencyTextField
                    autoComplete="off"
                    label="Range: Min"
                    value={
                      '$' +
                      roundWithoutCents(
                        Number(newItem?.minPriceRange)
                      ).toString()
                    }
                    onChange={(e) => {
                      setNewItem({ ...newItem, minPriceRange: e })
                      onItemValueChanged(unMaskedInput(e), 'minPriceRange')
                    }}
                    size="small"
                    labelVariant="h6"
                    mask={currencyMaskNegative}
                  />
                  <CurrencyTextField
                    autoComplete="off"
                    label="Range: Max"
                    value={
                      '$' +
                      roundWithoutCents(
                        Number(newItem?.maxPriceRange)
                      ).toString()
                    }
                    onChange={(e) => {
                      setNewItem({ ...newItem, maxPriceRange: e })
                      onItemValueChanged(unMaskedInput(e), 'maxPriceRange')
                    }}
                    size="small"
                    labelVariant="h6"
                    mask={currencyMaskNegative}
                  />
                </div>
              </Grid>
            )}

            {(newItem.measurement || !columnView) && (
              <Grid item xs={12}>
                <TextFieldLabel
                  autoComplete="off"
                  label={
                    <Typography display="inline" variant="h6">
                      Measurements:
                      <Typography
                        variant="h6"
                        display="inline"
                        marginLeft="2px"
                        color="var(--gray500)"
                      />
                    </Typography>
                  }
                  placeholder="Room size: 15’ x 19’5” x 8’ ceiling height"
                  value={newItem.measurement || ''}
                  size="small"
                  onChange={(e) => {
                    if (e.target.value.length > 998) return
                    setNewItem({ ...newItem, measurement: e.target.value })
                    onItemValueChanged(e.target.value, 'measurement')
                  }}
                  multiline
                  rows={2}
                  labelVariant="h6"
                // disabled={vendorIsPaid}
                />
              </Grid>
            )}
          </>
        )}

        <Grid container item spacing={2} alignItems="flex-end">
          <Grid item xs={2.4}>
            <TextFieldLabel
              autoComplete="off"
              label="Ref: "
              value={newItem.inspectionReportReference || ''}
              size="small"
              onChange={(e) => {
                setNewItem({
                  ...newItem,
                  inspectionReportReference: e.target.value,
                })
                onItemValueChanged(e.target.value, 'inspectionReportReference')
              }}
              labelVariant="h6"
            // disabled={vendorIsPaid}
            />
          </Grid>
          {viewType === 'OCR' && (
            <Grid item xs={9}>
              <TextFieldLabel
                autoComplete="off"
                label="Heading: "
                value={newItem.inspectionReportNoteHeader || ''}
                size="small"
                onChange={(e) => {
                  setNewItem({
                    ...newItem,
                    inspectionReportNoteHeader: e.target.value,
                  })
                  onItemValueChanged(
                    e.target.value,
                    'inspectionReportNoteHeader'
                  )
                }}
                labelVariant="h6"
              // disabled={vendorIsPaid}
              />
            </Grid>
          )}
          {viewType !== 'OCR' && (
            <>
              <Grid item xs={2.4}>
                <Typography variant="h6">Quantity:</Typography>
                <TextField
                  autoComplete="off"
                  value={newItem?.quantity}
                  size="small"
                  defaultValue={1}
                  onKeyDown={(e) => {
                    if (
                      e.code === 'Backspace' &&
                      newItem?.quantity?.toString()?.length === 1
                    ) {
                      setNewItem({
                        ...newItem,
                        quantity: '',
                      })
                    }
                  }}
                  onChange={(e) => handleChangeQuantity(e.target.value)}
                  type="number"
                />
              </Grid>
              {showOverride && (
                <Grid item xs={2.4}>
                  <CurrencyTextField
                    autoComplete="off"
                    label="Vendor Price: "
                    value={
                      '$' + round(Number(newItem?.totalCost) || 0, 2).toString()
                    }
                    size="small"
                    labelVariant="h6"
                    mask={currencyMask}
                    disabled
                  />
                </Grid>
              )}
              <Grid item xs={2.4}>
                <Typography variant="h6">Customer Price: </Typography>
                {itemTotalLoading ? (
                  <CircularProgress
                    style={{ marginTop: '.5rem' }}
                    size={'1.5rem'}
                  />
                ) : (
                  <CurrencyTextField
                    autoComplete="off"
                    label=""
                    value={
                      '$' + round(Number(newItem?.totalPrice), 2).toString()
                    }
                    onChange={(e) => {
                      setNewItem({ ...newItem, totalPrice: e })
                      onItemValueChanged(unMaskedInput(e), 'totalPrice')
                    }}
                    size="small"
                    labelVariant="h6"
                    mask={currencyMaskNegative}
                  />
                )}
              </Grid>
              {showOverride && (
                <Grid item xs={2.4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={newItem.requiredLicense}
                        onChange={() => {
                          setNewItem({
                            ...newItem,
                            requiredLicense: !newItem.requiredLicense,
                          })
                          onItemValueChanged(
                            !newItem.requiredLicense,
                            'requiredLicense'
                          )
                        }}
                        name="remember"
                        color="infoText"
                      />
                    }
                    className={styles.EstimateInfo__checkbox}
                    label={<Typography variant="h6">License:</Typography>}
                  />
                </Grid>
              )}

              {!showOverride && (
                <Grid item xs={2.4}>
                  <Button
                    //  disabled={vendorIsPaid}
                    fullWidth
                    onClick={() => setShowOverride(true)}
                    variant="outlined"
                    startIcon={<Icon name="Edit" />}
                  >
                    Override
                  </Button>
                </Grid>
              )}
            </>
          )}
        </Grid>
        {showOverride && (
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={2.4}>
              <CurrencyTextField
                autoComplete="off"
                label="Material Cost/Qty: "
                value={newItem.unitMaterialCost?.toString()}
                size="small"
                onChange={(e) => {
                  const valueMaterialCost = e
                  setNewItem({
                    ...newItem,
                    unitMaterialCost: valueMaterialCost,
                  })
                  onItemValueChanged(
                    unMaskedInput(valueMaterialCost),
                    'unitMaterialCost'
                  )
                }}
                onBlur={() => handleItemTotal('unitMaterialCost')}
                labelVariant="h6"
                mask={currencyMask}
              />
            </Grid>
            <Grid item xs={2.4}>
              <TextFieldLabel
                autoComplete="off"
                label="Hours/Qty: "
                value={`${newItem?.unitLaborHours || 0}`}
                onChange={(e) => {
                  setNewItem({ ...newItem, unitLaborHours: e.target.value })
                  dispatchContext({
                    type: workOdersTypes.SET_TOTALS_LOADING,
                    payload: true,
                  })
                  fetchItemsTotal(
                    {
                      ...newItem,
                      unitLaborHours: Number(e.target.value),
                    },
                    isEditModal
                  )
                  onItemValueChanged(e.target.value, 'unitLaborHours')
                }}
                onBlur={() => handleItemTotal('unitLaborHours')}
                size="small"
                type="number"
                labelVariant="h6"
              />
            </Grid>
            <Grid item xs={2.4}>
              <TextFieldLabel
                autoComplete="off"
                label="Margin: "
                value={`${newItem?.margin || '0'}`}
                onChange={(e) => {
                  setNewItem({ ...newItem, margin: Number(e.target.value) })
                  onItemValueChanged(Number(e.target.value), 'margin')
                }}
                onBlur={() => handleItemTotal('margin')}
                size="small"
                labelVariant="h6"
              />
            </Grid>
            <Grid item xs={2.4}>
              <CurrencyTextField
                autoComplete="off"
                labelColor="#0E905A"
                label="Profit: "
                value={newItem?.profit || '0'}
                size="small"
                onChange={(e) => { }}
                labelVariant="h6"
                mask={currencyMask}
                disabled
              />
            </Grid>
            <Grid item xs={2.4}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newItem.requiredPermit}
                    onChange={() => {
                      setNewItem({
                        ...newItem,
                        requiredPermit: !newItem.requiredPermit,
                      })
                      onItemValueChanged(
                        !newItem.requiredPermit,
                        'requiredPermit'
                      )
                    }}
                    name="remember"
                    color="infoText"
                  />
                }
                className={styles.EstimateInfo__checkbox}
                label={<Typography variant="h6">Permit:</Typography>}
              />
            </Grid>
          </Grid>
        )}
        {viewType !== 'OCR' && (
          <Grid item xs={12}>
            <SelectInput
              label="Display Disclaimer"
              placeholder="Disclaimer Text on Estimate"
              value={disclaimerArray || []}
              options={DISCLAIMER_OPTIONS.map((option) => option.value)}
              size="small"
              onChange={dispatchDisclaimer}
              onBlur={_handleBlur}
              clearOnBlur
              multiple
              freeSolo
              labelVariant="h6"
              maxCharacters={40}
            // disabled={vendorIsPaid}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <TextFieldLabel
            isTextArea
            autoComplete="off"
            label={
              <Typography display="inline" variant="h6">
                Public Notes/Inspection Comments{' '}
                <Typography
                  variant="h6"
                  display="inline"
                  marginLeft="2px"
                  color="var(--gray500)"
                >
                  (Visible to Customer):{' '}
                </Typography>
              </Typography>
            }
            placeholder="Repair Notes"
            value={newItem.inspectionReportNote || ''}
            size="small"
            onChange={(e) => {
              setNewItem({ ...newItem, inspectionReportNote: e.target.value })
              onItemValueChanged(e.target.value, 'inspectionReportNote')
            }}
            inputProps={{ style: { fontSize: '14px' } }}
            multiline
            textFieldStyle={{ fontSize: '14px' }}
            labelVariant="h6"
          // disabled={vendorIsPaid}
          />
        </Grid>
        {/* {!columnView && ( */}
        <Grid item xs={12}>
          <TextFieldLabel
            isTextArea
            autoComplete="off"
            label={
              <Typography display="inline" variant="h6">
                Repair Addendum Notes{' '}
                <Typography
                  variant="h6"
                  display="inline"
                  marginLeft="2px"
                  color="var(--gray500)"
                >
                  (Visible to Customer):{' '}
                </Typography>
              </Typography>
            }
            placeholder="Repair Addendum Notes"
            value={newItem.requestedRepairNote || ''}
            size="small"
            onChange={(e) => {
              setNewItem({ ...newItem, requestedRepairNote: e.target.value })
              onItemValueChanged(e.target.value, 'requestedRepairNote')
            }}
            multiline
            rows={2}
            labelVariant="h6"
            textFieldStyle={{ fontSize: '14px' }}
          // disabled={vendorIsPaid}
          />
        </Grid>
        {/* )} */}
      </Grid>
      <Grid item xs={columnView ? 12 : showOverride ? 4 : 6}>
        <Grid container item xs={12}>
          <Grid item xs={12} sx={{ marginBottom: 1 }}>
            <Typography sx={{ marginBottom: 1 }} variant="h6">
              Images:
            </Typography>
          </Grid>
          <MemoImagesSelector
            submitAction={submitAction}
            images={[...(item?.images || []), ...(editItem?.images || [])]}
            // imageFiles={item.imageFiles || []}
            imageFiles={
              columnView && editItem.imageFiles
                ? editItem.imageFiles || []
                : item.imageFiles || []
            }
            isSlider={isEditModal}
            hasImageLoader={true}
            handleAllImage={() => {
              setOpenImagesModal(true)
              /*    dispatchContext({ type: 'SET_MODAL_OPEN', payload: true })
                 dispatchContext({ type: 'SET_MODAL_TYPE', payload: 'ALL_IMAGES' }) */
            }}
            isNewSelector={columnView}
          />
        </Grid>

        <Modal
          setOpen={() => setOpenImagesModal(false)}
          open={openImagesModal}
          onClose={() => () => setOpenImagesModal(false)}
          title={'Image Gallery'}
          size={'xl'}
          fullScreen
          showClose={true}
        >
          <Images allImages downloadable hasTabs allOrders={!selectedTab} />
        </Modal>

        {!columnView && (
          <Grid item xs={12} marginTop={2}>
            <DropDown
              title={
                <Typography variant="h6" color="var(--blue700)">
                  Additional Notes
                </Typography>
              }
              iconColor="var(--blue700)"
              className={styles.EstimateInfo__dropdown}
            >
              <TextFieldLabel
                isTextArea
                autoComplete="off"
                label={
                  <Typography display="inline" variant="h6">
                    Public Repair Notes{' '}
                    <Typography
                      variant="h6"
                      display="inline"
                      marginLeft="2px"
                      color="var(--gray500)"
                    >
                      (Visible to Customer):{' '}
                    </Typography>
                  </Typography>
                }
                value={newItem.publicRepairNote || ''}
                size="small"
                onChange={(e) => {
                  setNewItem({ ...newItem, publicRepairNote: e.target.value })
                  onItemValueChanged(e.target.value, 'publicRepairNote')
                }}
                labelVariant="h6"
              />
            </DropDown>
          </Grid>
        )}
        {(showPriceMatch || isEditMode) && (
          <Box>
            {/*  <TextFieldLabel
              autoComplete="off"
              label="Ref: "
              value={newItem.inspectionReportReference || ''}
              size="small"
              onChange={(e) => {
                setNewItem({
                  ...newItem,
                  inspectionReportReference: e.target.value,
                })
                onItemValueChanged(e.target.value, 'inspectionReportReference')
              }}
              labelVariant="h6"
            // disabled={vendorIsPaid}
            /> */}
            <TextFieldLabel
              autoComplete="off"
              label={
                <Typography display="inline" variant="h6">
                  Price Match Comments
                  <Typography
                    variant="h6"
                    display="inline"
                    marginLeft="2px"
                    color="var(--gray500)"
                  />
                </Typography>
              }
              placeholder="Your comment"
              /* defaultValue={
                newItem.priceMatch?.comment ||
                editItem.priceMatch?.comment ||
                editItem.priceMatchComment ||
                ''
              } */
              value={
                isEditMode ? editItem.priceMatchComment || '' : editItem.priceMatchComment || newItem.priceMatchComment || ''
              }
              size="small"
              onChange={(e) => {
                if (e.target.value.length > 998) return
                setNewItem({ ...newItem, priceMatchComment: e.target.value })
                onItemValueChanged(e.target.value, 'priceMatchComment')
              }}
              multiline
              rows={2}
              labelVariant="h6"
              isTextArea
              inputProps={{ style: { fontSize: '14px' } }}
              textFieldStyle={{ fontSize: '14px' }}
            // disabled={vendorIsPaid}
            />
            <Grid container item xs={12}>
              <Grid item xs={12} sx={{ marginBottom: 1 }}>
                <Typography sx={{ marginBottom: 1 }} variant="h6">
                  Price Match File
                </Typography>
              </Grid>
              {(editItem?.priceMatchFile || newItem.priceMatchFile) ? (
                <Box className={styles.priceMatchFileName}>
                  <Box onClick={() => handleDownloadFile(`${FILES_URL}${filesUrlIncludesLastSlash ? "" : "/"}${editItem?.priceMatchFile?.name || newItem.priceMatchFile?.name}`)} flex={1}>
                    <Typography>
                      {editItem?.priceMatchFile?.name || newItem.priceMatchFile?.name}
                    </Typography>
                  </Box>
                  <Box
                    onClick={handleDeletePriceMatchFile}
                    className={styles.priceMatchFileDelete}
                  >
                    <icons.Delete color="error" />
                  </Box>
                </Box>
              ) : priceMatchFileLoading ? (
                <Loader />
              ) : (
                <Box>
                  <Typography marginBottom="12px">
                    No uploaded file for now
                  </Typography>
                  <ImagePicker
                    handleUploadFile={handleUploadPriceMatchFile}
                    handleDeleteFile={handleDeletePriceMatchFile}
                    multiple={false}
                    acceptedFiles={[
                      'image/*',
                      'application/pdf',
                      'application/msword',
                      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                    ]}
                  />
                </Box>
              )}
            </Grid>
          </Box>
        )}
      </Grid>
    </Grid>
  )
}
export default EstimateInfo
