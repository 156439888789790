import env from '@beam-australia/react-env'
import OfflineBoltIcon from '@mui/icons-material/OfflineBolt'
import { isEmpty } from 'lodash'
import { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { computerPointer } from 'src/assets'
import {
  ActionsButton,
  Box,
  Button,
  Grid,
  Typography
} from 'src/components/UI'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import { filesActions, itemsActions } from 'src/ducks/actions'
import { getMatch } from 'src/ducks/match/selectors'
import { getEstimateId } from 'src/ducks/selectors'
import { Item } from 'src/ducks/types'
import { FILE_TYPE, ParseFilesUrl } from 'src/helpers'
import EstimateInfo from '../OrderItems/Modals/AddItem/Tabs/EstimateInfo'
import Comments from '../OrderItems/Modals/AddItem/Tabs/Comments'
import NewItemComments from '../OrderItems/Modals/AddItem/Tabs/EstimateInfo/NewItemComments'
import { WorkOrdersContext } from '../context'
import { MODAL_TYPE, workOdersTypes } from '../context/types'

const FILES_URL = env('FILES_URL') ?? ''

const ItemView = ({ view }: { view: 'OCR' | 'EDIT' }) => {
  const dispatch = useDispatch()
  const {
    dispatch: dispatchContext,
    state: { item, editItem, selectedOrderId, selectedItemId },
  } = useContext(WorkOrdersContext)
  const [isEditModal, setIsEditModal] = useState(true)

  const { id } = useParams()

  const estimateId = useSelector(getEstimateId())
  const itemMatch = useSelector(getMatch())

  const [buttonLoading, setButtonLoading] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  const handleRemoveItem = () => {
    dispatchContext({ type: 'SET_MODAL_OPEN', payload: true })
    dispatchContext({
      type: workOdersTypes.SET_MODAL_TYPE,
      payload: MODAL_TYPE.DELETE_ONE_REFRESH,
    })
  }

  const uploadImages = (auxAction?: 'CREATE_NEW' | 'CLONE', overrideItem?: Item) => {
    setButtonLoading(true)
    if (item?.images?.length || editItem?.images?.length) {
      const images = isEditModal ? editItem.images : item.images
      dispatch(
        filesActions.uploadFiles(
          images as File[],
          (uploadSucc, fileNameList) => {
            if (
              uploadSucc &&
              fileNameList &&
              fileNameList.length === (images as File[]).length
            ) {
              const imageFiles = (images as File[]).map((_image, index) => {
                const fileName = fileNameList[index]
                return {
                  name: fileName,
                  fileUrl: ParseFilesUrl({
                    baseUrl: FILES_URL,
                    avatar: fileName ?? '',
                  }),
                  description: '',
                  fileType: FILE_TYPE.INSPECTION_IMAGE,
                }
              })
              if (!isEditModal) {
                handleCreate({ imageFiles, auxAction })
              } else {
                handleEditItem({ imageFiles, auxAction, overrideItem })
              }
            } else {
              setButtonLoading(false)
            }
          }
        )
      )
    } else {
      if (!isEditModal) {
        handleCreate({ auxAction })
      } else {
        handleEditItem({ auxAction, overrideItem })
      }
    }
  }


  const handleCreate = ({
    imageFiles,
    auxAction,
  }: {
    imageFiles?: any
    auxAction?: 'CREATE_NEW' | 'CLONE'
  }) => {
    const request = {
      ...item,
      unitLaborHours: item.unitLaborHours,
      orderId: selectedOrderId,
      estimateId: estimateId,
      imageFiles,
      isEstimate: true,
    }
    dispatch(
      itemsActions.createItem(request, (success, newItem) => {
        setButtonLoading(false)
        dispatchContext({ type: 'SET_PAGE_LOADING', payload: false })
        dispatchContext({
          type: 'SET_ITEM_VALUE',
          payload: { attr: 'images', value: [] },
        })
        if (success) {

          dispatch(
            itemsActions.fetchItems(
              { params: { jobId: estimateId } },
              (succ) => { }
            )
          )
          setIsEditModal(true)
          dispatchContext({ type: 'RESET_ITEM_VALUE' })
          switch (auxAction) {
            case 'CREATE_NEW':
              dispatchContext({ type: 'RESET_ITEM_VALUE' })
              setIsEditModal(false)
              break
            case 'CLONE':
              dispatchContext({
                type: 'SET_ITEM_VALUE',
                payload: { attr: 'displayTitle', value: newItem?.title },
              })
              break
            default:
              break
          }
        }
      })
    )
  }
  
  const handleEditItem = ({
    imageFiles,
    auxAction,
    overrideItem
  }: {
    imageFiles?: any
    auxAction?: 'CREATE_NEW' | 'CLONE',
    overrideItem?: Item
  }) => {
    if (!item.itemId) return


    let request: any;
    if (overrideItem) {
      request = { ...overrideItem }
    } else {
      request = { ...editItem }
    }

    if ('unitMaterialCost' in request)
      request.unitMaterialCost = Number(request.unitMaterialCost)
    if ('unitLaborHours' in request)
      request.unitLaborHours = Number(request.unitLaborHours)
    if ('margin' in request) request.margin = Number(request.margin)
    if ('totalLaborCost' in request)
      request.totalLaborCost = Number(request.totalLaborCost)
    if ('totalPrice' in request) request.totalPrice = Number(request.totalPrice)
    if ('minPriceRange' in request && request.minPriceRange)
      request.minPriceRange = Number(request.minPriceRange)
    if ('evaluateReason' in request && request.evaluateReason)
      request.evaluateReason = String(request.evaluateReason)
    if ('maxPriceRange' in request && request.maxPriceRange)
      request.maxPriceRange = Number(request.maxPriceRange)
    if ('quantity' in request && !!request.quantity) request.quantity = request.quantity || editItem.quantity || item.quantity


    if (editItem?.priceMatchFile || (!editItem?.priceMatchFile && item?.priceMatch?.file)) {
      const filesUrlIncludesLastSlash = FILES_URL[FILES_URL.length - 1] === "/"
      request.priceMatch = {
        ...request.priceMatch,
        file: {
          name: editItem?.priceMatchFile?.name,
          fileUrl: `${FILES_URL}${filesUrlIncludesLastSlash ? "" : "/"}${editItem?.priceMatchFile?.name}`,
          description: '',
          fileType: FILE_TYPE.PRICE_MATCH,
        } as any
      }
      if (!editItem?.priceMatchFile && item?.priceMatch?.file) {
        request.priceMatch = {
          ...request.priceMatch,
          file: null
        }
      }
      delete request.priceMatchFile
      delete request.priceMatchComment
    }

    if (editItem?.priceMatchComment || (!editItem?.priceMatchComment && item?.priceMatch?.comment)) {
      request.priceMatch = {
        ...request.priceMatch,
        comment: editItem.priceMatchComment
          ? editItem.priceMatchComment
          : null,
      }
      delete request.priceMatchFile
      delete request.priceMatchComment
    }
    if (item?.priceMatch?.id) {
      request.priceMatch = {
        ...request.priceMatch,
        id: item.priceMatch.id,
      }
    }

    if (!editItem.priceMatchFile && !editItem.priceMatch?.file) {

      request.priceMatch = {
        ...request.priceMatch,
        file: null,
      }
    }
    if (!editItem.priceMatchComment && !editItem.priceMatch?.comment) {

      request.priceMatch = {
        ...request.priceMatch,
        comment: null,
      }
    }

    if (imageFiles) {
      const images: any = [...(item.imageFiles || []), ...(imageFiles || [])]
      request.imageFiles = images
    }
    dispatch(
      itemsActions.updateItem(
        {
          itemId: item.itemId,
          isEstimate: true,
          partialItem: request,
          selectedOrderId: selectedOrderId || undefined,
        },
        (success: boolean, newItem?: Item) => {
          setButtonLoading(false)
          dispatchContext({ type: 'SET_PAGE_LOADING', payload: false })
          dispatchContext({ type: workOdersTypes.SET_ITEM, payload: { ...item, ...newItem } })
          if (success) {
            dispatch(
              itemsActions.fetchItems(
                { params: { jobId: estimateId } },
                (succ) => {
                  if (overrideItem) {
                    dispatchContext({
                      type: 'SET_ITEM',
                      payload: newItem,
                    })
                    dispatchContext({
                      type: 'SET_ITEM_VALUE',
                      payload: { attr: 'displayTitle', value: newItem?.title },
                    })
                  }
                }
              )
            )
            dispatchContext({ type: 'RESET_EDIT_ITEM_VALUE' })
            switch (auxAction) {
              case 'CREATE_NEW':
                dispatchContext({ type: 'RESET_ITEM_VALUE' })

                dispatchContext({ type: 'SET_MODAL_OPEN', payload: true })
                if (view === 'EDIT') {
                  dispatchContext({
                    type: 'SET_MODAL_TYPE',
                    payload: 'EDIT_ITEM',
                  })
                } else {
                  dispatchContext({
                    type: 'SET_MODAL_TYPE',
                    payload: MODAL_TYPE.ADD_OCR_ITEM,
                  })
                }
                break
              case 'CLONE':
                const newRequest = {
                  ...item,
                  ...editItem,
                  unitLaborHours: item.unitLaborHours,
                  orderId: selectedOrderId,
                  estimateId: estimateId,
                  imageFiles: item.imageFiles,
                  images: editItem.imageFiles,
                  isEstimate: true,
                }
                dispatch(
                  itemsActions.createItem(newRequest, (success, itemNew) => {
                    if (success) {
                      dispatchContext({ type: 'SET_ITEM', payload: { ...itemNew } })
                      dispatchContext({
                        type: 'SET_ITEM_VALUE',
                        payload: { attr: 'displayTitle', value: itemNew?.title },
                      })
                    }
                  }))
                break
              default:
                break
            }
          }
        }
      )
    )
  }


  useEffect(() => {
    if (item.priceMatch) {
      console.log("llega aca")
      dispatchContext({
        type: 'SET_EDIT_ITEM_VALUE',
        payload: { attr: 'priceMatchComment', value: item.priceMatch.comment },
      })
      dispatchContext({
        type: 'SET_EDIT_ITEM_VALUE',
        payload: { attr: 'priceMatchFile', value: item.priceMatch.file },
      })
      dispatchContext({ type: "TOGGLE_SHOW_PRICE_MATCH", payload: true })
    }
  }, [item])

  const handleMultiMatches = () => {
    dispatchContext({ type: 'SET_MODAL_OPEN', payload: true })
    dispatchContext({
      type: workOdersTypes.SET_MODAL_TYPE,
      payload: MODAL_TYPE.MULTI_MATCHES,
    })
  }

  return (
    <Box height="calc(100vh - 215px)" overflow="scroll">
      {isEmpty(item) ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          height="100%"
          gap={3}
        >
          <img src={computerPointer} alt="Computer Pointer" />
          <Typography fontSize="16px" lineHeight="28px">
            Select an item from the table to edit
          </Typography>
        </Box>
      ) : (
        <Box>
          <Grid container justifyContent="space-between">
            <Grid
              item
              container
              mt={1}
              ml={0}
              alignItems="center"
              style={{ gap: '8px', width: 'fit-content' }}
            >
              {Number(item.automatchConfidenceScore) >= 90 &&
                view === 'EDIT' && (
                  <OfflineBoltIcon style={{ width: '16px', height: '16px' }} />
                )}
              <Typography fontSize="14px" lineHeight="24px">
                {item.title}
              </Typography>
              {Number(item.automatchConfidenceScore) >= 90 &&
                view === 'EDIT' && (
                  <Box
                    padding="4px"
                    bgcolor="var(--green100)"
                    color="var(--green900)"
                  >
                    <Typography
                      fontSize="12px"
                      lineHeight="18px"
                      fontWeight={500}
                    >{`${item.automatchConfidenceScore}% Matched`}</Typography>
                  </Box>
                )}
            </Grid>
            {view === 'EDIT' && (itemMatch.matches?.length || 0) > 1 && (
              <Grid item>
                <Button
                  variant="containedLight"
                  onClick={handleMultiMatches}
                  style={{
                    boxShadow:
                      '0px 0px 1px 0px rgba(26, 42, 85, 0.31), 0px 1px 1px 0px rgba(26, 42, 85, 0.24)',
                  }}
                >
                  See multiple results
                </Button>
              </Grid>
            )}
          </Grid>
          {view === 'EDIT' && <EstimateInfo columnView viewType="EDIT" onItemChange={(auxItem) => uploadImages(undefined, auxItem)} />}
          {view === 'OCR' && <EstimateInfo columnView viewType="OCR" />}
          <Grid
            item
            container
            xs={7}
            spacing={2}
            justifyContent="flex-start"
            marginRight={3}
            marginTop={2}
            style={{ maxWidth: '100%' }}
          >
            <Grid item xs={2}>
              <ActionsButton
                icon={<Icon name="ArrowDropDown" />}
                text="Save"
                onClick={uploadImages}
                iconPosition="end"
                loading={buttonLoading}
                disabled={!item.title}
                actions={[
                  {
                    label: 'Save & Create New',
                    onClick: () => {
                      uploadImages('CREATE_NEW')
                    },
                  },
                  {
                    label: 'Save & Clone',
                    onClick: () => {
                      uploadImages('CLONE')
                    },
                  },
                ]}
                variant="contained"
                sx={{
                  minWidth: '100% !important',
                  display: 'flex',
                  justifyContent: 'space-between',
                  backgroundColor: 'var(--blue700)',
                  borderColor: 'var(--blue700)',
                  '&:hover': {
                    backgroundColor: 'var(--blue800)',
                    borderColor: 'var(--blue800)',
                  },
                }}
              />
            </Grid>
            <Grid item xs={2.5}>
              <Button
                variant={'containedErrorLight'}
                onClick={handleRemoveItem}
                fullWidth
              >
                {'Remove Item'}
              </Button>
            </Grid>
          </Grid>

          {view === 'EDIT' && (
            <Grid container item xs={12} mt={2}>
              {item.itemId ? <Comments /> : <NewItemComments />}
            </Grid>
          )}
        </Box>
      )}
    </Box>
  )
}

export default ItemView
